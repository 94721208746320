import api from '../../../app/utils/axios';

export const projectApi = {
    getProjects: async () => {
        const response = await api.get('/projects');
        return response.data;
    },

    getProject: async (id) => {
        const response = await api.get(`/projects/${id}`);
        return response.data;
    },

    createProject: async (data) => {
        const response = await api.post('/projects', data);
        return response.data;
    },

    updateProject: async (id, data) => {
        const response = await api.put(`/projects/${id}`, data);
        return response.data;
    },

    deleteProject: async (id) => {
        const response = await api.delete(`/projects/${id}`);
        return response.data;
    }
};
