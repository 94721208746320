import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Avatar,
    Grid,
    Chip
} from '@mui/material';
import { format } from 'date-fns';
import { useAuth } from '../../../app/hooks/useAuth';

export const DeveloperProfile = () => {
    const { developer } = useAuth();

    return (
        <Card>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <Avatar
                        sx={{ width: 64, height: 64, mr: 2 }}
                    >
                        {developer.developerName[0]}
                    </Avatar>
                    <Box>
                        <Typography variant="h5">{developer.developerName}</Typography>
                        <Typography color="textSecondary">{developer.email}</Typography>
                    </Box>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" color="textSecondary">
                            Üyelik Tarihi
                        </Typography>
                        <Typography>
                            {format(new Date(developer.createdAt * 1000), 'dd/MM/yyyy')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" color="textSecondary">
                            Durum
                        </Typography>
                        <Chip
                            label={developer.status}
                            color={developer.status === 'ACTIVE' ? 'success' : 'default'}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" color="textSecondary">
                            Proje Sayısı
                        </Typography>
                        <Typography>
                            {developer.projects?.length || 0}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" color="textSecondary">
                            Email Doğrulama
                        </Typography>
                        <Chip
                            label={developer.emailVerified ? 'Doğrulanmış' : 'Doğrulanmamış'}
                            color={developer.emailVerified ? 'success' : 'warning'}
                            size="small"
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};