import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Container,
    Box,
    Paper,
    Typography,
    Link
} from '@mui/material';
import { RegisterForm } from '../components/RegisterForm';

export const RegisterPage = () => {
    return (
        <Container maxWidth="sm">
            <Box sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Paper sx={{ p: 4, width: '100%' }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Join GameStack
                    </Typography>
                    <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
                        Powerful backend services for your game
                    </Typography>

                    <RegisterForm />

                    <Box sx={{ mt: 2, textAlign: 'center' }}>
                        <Typography variant="body2">
                            Already have an account?{' '}
                            <Link component={RouterLink} to="/login">
                                Login
                            </Link>
                        </Typography>
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
};