import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import {
    TextField,
    Button,
    Box,
    Alert,
    CircularProgress
} from '@mui/material';
import { loginSchema } from '../../../app/utils/validation';
import { useAuth } from '../../../app/hooks/useAuth';
import { useApi } from '../../../app/hooks/useApi';
import { authApi } from '../api/authApi';

export const LoginForm = () => {
    const { login } = useAuth();
    const { mutation } = useApi();
    const navigate = useNavigate();

    const { mutate, isLoading, error } = mutation(
        async (values) => {
            try {
                const result = await authApi.login(values);
                if (result.token && result.developer) {
                    login(result.token, result.developer);
                    navigate('/projects');
                }
            } catch (err) {
                throw new Error(err.response?.data?.message || 'Login failed');
            }
        }
    );

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={loginSchema}
            onSubmit={mutate}
        >
            {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error.message}
                        </Alert>
                    )}

                    <TextField
                        fullWidth
                        name="email"
                        label="Email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        margin="normal"
                    />

                    <TextField
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        margin="normal"
                    />

                    <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        disabled={isLoading}
                        sx={{ mt: 2 }}
                    >
                        {isLoading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            'Login'
                        )}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};