import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Paper,
    Typography,
    Tabs,
    Tab,
    TextField,
    Alert,
    Chip,
    IconButton,
    Tooltip,
    Divider
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { LoadingButton } from '@mui/lab';
import {
    ContentCopy as CopyIcon,
    Refresh as RefreshIcon,
    PlayArrow as RunIcon,
    Save as SaveIcon,
    Delete as DeleteIcon,
    Code as CodeIcon,
    Terminal as TerminalIcon
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { apiService } from '../../../app/services/apiService';
import { endpoints } from '../../../app/utils/axios';
import { generateCurlCommand } from './CurlGenerator';

const INITIAL_TEST_CASES = {
    auth: {
        signin: {
            method: 'POST',
            endpoint: endpoints.auth.signin,
            body: {
                email: "newuser@example.com",
                password: "Password123"
            },
            requiresAuth: false
        },
        signup: {
            method: 'POST',
            endpoint: endpoints.auth.signup,
            body: {
                email: "newuser@example.com",
                password: "Password123",
                developerName: "Test Developer"
            }
        },
        signout: {
            method: 'POST',
            endpoint: endpoints.auth.signout,
            body: {}
        }
    },
    developers: {
        getProfile: {
            method: 'GET',
            endpoint: endpoints.developers.me,
            body: {}
        },
        updateProfile: {
            method: 'PUT',
            endpoint: endpoints.developers.me,
            body: {
                developerName: "Updated Name",
                settings: {
                    notificationsEnabled: true,
                    theme: "dark"
                }
            }
        }
    },
    projects: {
        create: {
            method: 'POST',
            endpoint: endpoints.projects.create,
            body: {
                name: "Test Project",
                description: "A test project",
                settings: {
                    enableAuth: true,
                    enableAnalytics: true,
                    enableStore: false
                }
            },
            onSuccess: (response, context) => {
                if (response.project?.projectId) {
                    localStorage.setItem('currentTestProjectId', response.project.projectId);
                }
                return response;
            }
        },
        list: {
            method: 'GET',
            endpoint: endpoints.projects.list,
            body: {}
        },
        get: {
            method: 'GET',
            endpoint: () => {
                const projectId = localStorage.getItem('currentTestProjectId') || 'proj_123';
                return endpoints.projects.get(projectId);
            },
            body: {},
            dynamicEndpoint: true
        },
        update: {
            method: 'PUT',
            endpoint: () => {
                const projectId = localStorage.getItem('currentTestProjectId') || 'proj_123';
                return endpoints.projects.update(projectId);
            },
            body: {
                name: "Updated Project",
                description: "Updated description",
                settings: {
                    enableAuth: true,
                    enableAnalytics: true,
                    enableStore: true
                }
            },
            dynamicEndpoint: true
        },
        delete: {
            method: 'DELETE',
            endpoint: () => {
                const projectId = localStorage.getItem('currentTestProjectId') || 'proj_123';
                return endpoints.projects.delete(projectId);
            },
            body: {},
            dynamicEndpoint: true,
            onSuccess: (response) => {
                localStorage.removeItem('currentTestProjectId');
                return response;
            }
        }
    }
};

const TestPanel = ({
                       title,
                       method,
                       endpoint: rawEndpoint,
                       initialBody,
                       requiresAuth = true,
                       onSaveResponse,
                       savedResponses = [],
                       dynamicEndpoint = false,
                       onSuccess
                   }) => {
    const [body, setBody] = useState(JSON.stringify(initialBody, null, 2));
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showCurl, setShowCurl] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const getEndpoint = () => {
        if (dynamicEndpoint && typeof rawEndpoint === 'function') {
            return rawEndpoint();
        }
        return rawEndpoint;
    };

    const handleTest = async () => {
        try {
            setLoading(true);
            setError(null);

            let parsedBody;
            try {
                parsedBody = JSON.parse(body);
            } catch (err) {
                setError('Invalid JSON format in request body');
                return;
            }

            const endpoint = getEndpoint();
            console.log('Sending request:', {
                method,
                endpoint,
                body: parsedBody
            });

            const result = await apiService.testEndpoint(method, endpoint, parsedBody);

            let processedResponse = result.data;
            if (onSuccess) {
                processedResponse = onSuccess(result.data);
            }

            setResponse(processedResponse);
            if (onSaveResponse) {
                onSaveResponse(processedResponse);
            }

            // Token işleme
            if (processedResponse?.token) {
                localStorage.setItem('token', processedResponse.token);
                if (processedResponse.developer) {
                    localStorage.setItem('developer', JSON.stringify(processedResponse.developer));
                }
                enqueueSnackbar('Authentication successful', { variant: 'success' });
            }

        } catch (err) {
            console.error('Test error:', err);
            let errorMessage = err.response?.data?.message || err.message;
            setError(errorMessage);

            // 401 hatası geldiğinde sadece local storage'ı temizle
            if (err.response?.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('developer');
                enqueueSnackbar('Authentication failed', { variant: 'error' });
            }
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        enqueueSnackbar('Copied to clipboard', { variant: 'success' });
    };

    const currentEndpoint = getEndpoint();

    return (
        <Paper sx={{ p: 2, mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box>
                    <Typography variant="h6">
                        {title}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                        {method} {currentEndpoint}
                        {requiresAuth && (
                            <Chip
                                size="small"
                                label="Requires Auth"
                                color="primary"
                                sx={{ ml: 1 }}
                            />
                        )}
                        {dynamicEndpoint && (
                            <Chip
                                size="small"
                                label="Dynamic Endpoint"
                                color="secondary"
                                sx={{ ml: 1 }}
                            />
                        )}
                    </Typography>
                </Box>
                <Box>
                    <Tooltip title="Show cURL command">
                        <IconButton onClick={() => setShowCurl(!showCurl)}>
                            <TerminalIcon />
                        </IconButton>
                    </Tooltip>
                    {savedResponses.length > 0 && savedResponses.map((saved, index) => (
                        <Chip
                            key={index}
                            label={`Test ${index + 1}`}
                            size="small"
                            onDelete={() => {/* implement delete */}}
                            sx={{ ml: 1 }}
                        />
                    ))}
                </Box>
            </Box>

            {showCurl && (
                <TextField
                    fullWidth
                    multiline
                    value={generateCurlCommand(
                        method,
                        currentEndpoint,
                        JSON.parse(body),
                        localStorage.getItem('token')
                    )}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <IconButton onClick={() => copyToClipboard(generateCurlCommand(
                                method,
                                currentEndpoint,
                                JSON.parse(body),
                                localStorage.getItem('token')
                            ))}>
                                <CopyIcon />
                            </IconButton>
                        )
                    }}
                    sx={{ mb: 2, fontFamily: 'monospace' }}
                />
            )}

            <TextField
                fullWidth
                multiline
                rows={4}
                value={body}
                onChange={(e) => setBody(e.target.value)}
                placeholder="Request Body (JSON)"
                variant="outlined"
                sx={{ mb: 2, fontFamily: 'monospace' }}
            />

            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                <LoadingButton
                    variant="contained"
                    onClick={handleTest}
                    loading={loading}
                    startIcon={<RunIcon />}
                >
                    Test
                </LoadingButton>

                <Tooltip title="Copy request body">
                    <IconButton onClick={() => copyToClipboard(body)}>
                        <CopyIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Reset request body">
                    <IconButton onClick={() => setBody(JSON.stringify(initialBody, null, 2))}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            {response && (
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="subtitle2">Response:</Typography>
                        <Box>
                            <Tooltip title="Copy response">
                                <IconButton
                                    size="small"
                                    onClick={() => copyToClipboard(JSON.stringify(response, null, 2))}
                                >
                                    <CopyIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Save response">
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        if (onSaveResponse) {
                                            onSaveResponse(response);
                                            enqueueSnackbar('Response saved', { variant: 'success' });
                                        }
                                    }}
                                >
                                    <SaveIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Paper
                        sx={{
                            p: 2,
                            bgcolor: 'grey.900',
                            color: 'common.white',
                            fontFamily: 'monospace',
                            overflow: 'auto',
                            maxHeight: 400
                        }}
                    >
                        <pre>{JSON.stringify(response, null, 2)}</pre>
                    </Paper>
                </Box>
            )}
        </Paper>
    );
};

export const SuperSecretTest = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [savedResponses, setSavedResponses] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const error = params.get('error');
        if (error) {
            enqueueSnackbar(decodeURIComponent(error), { variant: 'error' });
        }
    }, [enqueueSnackbar]);

    const handleDeleteAllData = () => {
        try {
            localStorage.clear();
            setSavedResponses({});
            enqueueSnackbar('All test data cleared', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar('Error clearing data', { variant: 'error' });
        }
    };

    const handleSaveResponse = (endpoint, response) => {
        try {
            setSavedResponses(prev => ({
                ...prev,
                [endpoint]: [...(prev[endpoint] || []), response]
            }));
            enqueueSnackbar('Response saved', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar('Error saving response', { variant: 'error' });
        }
    };

    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4">
                    API Test Console
                </Typography>
                <LoadingButton
                    variant="outlined"
                    color="error"
                    onClick={handleDeleteAllData}
                    startIcon={<DeleteIcon />}
                >
                    Clear All Test Data
                </LoadingButton>
            </Box>

            <Paper sx={{ mb: 3 }}>
                <Tabs value={activeTab} onChange={(e, v) => setActiveTab(v)}>
                    <Tab label="Auth" />
                    <Tab
                        label="Developers"
                        disabled={!localStorage.getItem('token')}
                    />
                    <Tab
                        label="Projects"
                        disabled={!localStorage.getItem('token')}
                    />
                </Tabs>
            </Paper>

            <Box sx={{ mb: 2 }}>
                <Alert severity="info">
                    Current Token: {localStorage.getItem('token') ? localStorage.getItem('token') : 'Not Present'}
                </Alert>
                {activeTab === 2 && localStorage.getItem('currentTestProjectId') && (
                    <Alert severity="info" sx={{ mt: 1 }}>
                        Current Test Project ID: {localStorage.getItem('currentTestProjectId')}
                    </Alert>
                )}
            </Box>

            {/* Auth Tests */}
            {activeTab === 0 && (
                <Box>
                    {Object.entries(INITIAL_TEST_CASES.auth).map(([key, test]) => (
                        <TestPanel
                            key={key}
                            title={key.charAt(0).toUpperCase() + key.slice(1)}
                            method={test.method}
                            endpoint={test.endpoint}
                            initialBody={test.body}
                            requiresAuth={key !== 'signin' && key !== 'signup'}
                            onSaveResponse={(response) => handleSaveResponse(key, response)}
                            savedResponses={savedResponses[key]}
                        />
                    ))}
                </Box>
            )}

            {/* Developers Tests */}
            {activeTab === 1 && (
                <Box>
                    {Object.entries(INITIAL_TEST_CASES.developers).map(([key, test]) => (
                        <TestPanel
                            key={key}
                            title={key.charAt(0).toUpperCase() + key.slice(1)}
                            method={test.method}
                            endpoint={test.endpoint}
                            initialBody={test.body}
                            onSaveResponse={(response) => handleSaveResponse(key, response)}
                            savedResponses={savedResponses[key]}
                        />
                    ))}
                </Box>
            )}

            {/* Projects Tests */}
            {activeTab === 2 && (
                <Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1" color="text.secondary">
                            Test Flow: Create a project → Get project details → Update project → List all projects → Delete project
                        </Typography>
                    </Box>
                    {Object.entries(INITIAL_TEST_CASES.projects).map(([key, test]) => (
                        <TestPanel
                            key={key}
                            title={key.charAt(0).toUpperCase() + key.slice(1)}
                            method={test.method}
                            endpoint={test.endpoint}
                            initialBody={test.body}
                            dynamicEndpoint={test.dynamicEndpoint}
                            onSuccess={test.onSuccess}
                            onSaveResponse={(response) => handleSaveResponse(key, response)}
                            savedResponses={savedResponses[key]}
                        />
                    ))}
                    <Box sx={{ mt: 2 }}>
                        <Alert severity="warning">
                            Note: For Get, Update, and Delete operations, make sure to create a project first or provide a valid Project ID.
                            Current Test Project ID: {localStorage.getItem('currentTestProjectId') || 'None'}
                        </Alert>
                    </Box>
                </Box>
            )}

            {/* API Key Info */}
            {activeTab === 2 && localStorage.getItem('currentTestProjectId') && (
                <Paper sx={{ mt: 2, p: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Project Test Status
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant="body2">
                            <strong>Project ID:</strong> {localStorage.getItem('currentTestProjectId')}
                        </Typography>
                        <Typography variant="body2">
                            <strong>Last Test:</strong> {savedResponses[localStorage.getItem('currentTestProjectId')]?.length > 0
                            ? new Date().toLocaleString()
                            : 'No tests yet'}
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <LoadingButton
                            variant="outlined"
                            size="small"
                            color="warning"
                            onClick={() => {
                                localStorage.removeItem('currentTestProjectId');
                                enqueueSnackbar('Test project ID cleared', { variant: 'success' });
                            }}
                            startIcon={<DeleteIcon />}
                        >
                            Clear Test Project ID
                        </LoadingButton>
                    </Box>
                </Paper>
            )}
        </Container>
    );
};