import React from 'react';
import { useParams } from 'react-router-dom';
import {
    Container,
    Typography,
    Box,
    Paper,
    Tabs,
    Tab,
    Button,
    Grid,
    Chip,
    Alert,
    TextField,
    IconButton
} from '@mui/material';
import {
    ContentCopy as ContentCopyIcon,
    Refresh as RefreshIcon
} from '@mui/icons-material';
import { useApi } from '../../../app/hooks/useApi';
import { projectApi } from '../api/projectApi';
import { CircularProgress } from '@mui/material';
export const ProjectDetailPage = () => {
    const { projectId } = useParams();
    const [activeTab, setActiveTab] = React.useState(0);
    const { query } = useApi();
    const { data, isLoading } = query(
        ['project', projectId],
        () => projectApi.getProject(projectId)
    );

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        // Show success notification
    };

    const handleRegenerateKey = async () => {
        // Implement API key regeneration
    };

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    const project = data?.project;

    return (
        <Container maxWidth="lg">
            <Box sx={{ mb: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h4">{project.name}</Typography>
                    <Chip
                        label={project.status}
                        color={project.status === 'ACTIVE' ? 'success' : 'default'}
                    />
                </Box>

                <Typography color="textSecondary">
                    Project ID: {project.projectId}
                </Typography>
            </Box>

            <Paper sx={{ mb: 3 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)}>
                        <Tab label="Genel Bakış" />
                        <Tab label="API Anahtarları" />
                        <Tab label="Ayarlar" />
                        <Tab label="Entegrasyon" />
                    </Tabs>
                </Box>

                <Box sx={{ p: 3 }}>
                    {activeTab === 0 && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Paper sx={{ p: 2 }}>
                                    <Typography variant="h6" gutterBottom>
                                        API Kullanımı
                                    </Typography>
                                    <Typography variant="h3">
                                        {project.apiCalls || 0}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        Son 24 saat
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Paper sx={{ p: 2 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Aktif Kullanıcılar
                                    </Typography>
                                    <Typography variant="h3">
                                        {project.activeUsers || 0}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        Anlık
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    )}

                    {activeTab === 1 && (
                        <Box>
                            <Alert severity="warning" sx={{ mb: 3 }}>
                                API anahtarınızı güvenli bir şekilde saklayın ve public ortamlarda paylaşmayın.
                            </Alert>

                            <Paper sx={{ p: 2 }}>
                                <Typography variant="subtitle2" gutterBottom>
                                    API Key
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <TextField
                                        fullWidth
                                        value={project.apiKey}
                                        InputProps={{ readOnly: true }}
                                    />
                                    <IconButton onClick={() => handleCopy(project.apiKey)}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                    <IconButton onClick={handleRegenerateKey} color="warning">
                                        <RefreshIcon />
                                    </IconButton>
                                </Box>
                            </Paper>
                        </Box>
                    )}

                    {activeTab === 2 && (
                        <Box>
                            <Typography variant="h6" gutterBottom>
                                Özellik Ayarları
                            </Typography>
                            {/* Project settings form */}
                        </Box>
                    )}

                    {activeTab === 3 && (
                        <Box>
                            <Typography variant="h6" gutterBottom>
                                Unity SDK Entegrasyonu
                            </Typography>
                            {/* Integration guide and code samples */}
                        </Box>
                    )}
                </Box>
            </Paper>
        </Container>
    );
};