export const generateCurlCommand = (method, endpoint, body, token = null) => {
    let command = `curl -X ${method} '${process.env.REACT_APP_API_URL}${endpoint}'`;
    command += " -H 'Content-Type: application/json'";

    if (token) {
        command += ` -H 'Authorization: Bearer ${token}'`;
    }

    if (Object.keys(body).length > 0) {
        command += ` -d '${JSON.stringify(body)}'`;
    }

    return command;
};