import React, { useState } from 'react';
import {
    Container,
    Typography,
    Grid,
    Button,
    Box
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useApi } from '../../../app/hooks/useApi';
import { projectApi } from '../api/projectApi';
import { ProjectCard } from '../components/ProjectCard';
import { ProjectForm } from '../components/ProjectForm';
import { CircularProgress } from '@mui/material';
export const ProjectsPage = () => {
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    const { query, mutation } = useApi();

    const { data: projectsData, isLoading } = query(
        'projects',
        projectApi.getProjects
    );

    const createMutation = mutation(
        projectApi.createProject,
        {
            successMessage: 'Proje oluşturuldu',
            invalidateQueries: ['projects']
        }
    );

    const updateMutation = mutation(
        (data) => projectApi.updateProject(selectedProject.projectId, data),
        {
            successMessage: 'Proje güncellendi',
            invalidateQueries: ['projects']
        }
    );

    const deleteMutation = mutation(
        projectApi.deleteProject,
        {
            successMessage: 'Proje silindi',
            invalidateQueries: ['projects']
        }
    );

    const handleSubmit = async (values) => {
        if (selectedProject) {
            await updateMutation.mutateAsync(values);
        } else {
            await createMutation.mutateAsync(values);
        }
        handleCloseForm();
    };

    const handleEdit = (project) => {
        setSelectedProject(project);
        setIsFormOpen(true);
    };

    const handleCloseForm = () => {
        setSelectedProject(null);
        setIsFormOpen(false);
    };

    return (
        <Container maxWidth="lg">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                <Typography variant="h4">
                    Projeler
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setIsFormOpen(true)}
                >
                    Yeni Proje
                </Button>
            </Box>

            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={3}>
                    {projectsData?.projects?.map((project) => (
                        <Grid item xs={12} sm={6} md={4} key={project.projectId}>
                            <ProjectCard
                                project={project}
                                onDelete={(id) => deleteMutation.mutate(id)}
                                onEdit={handleEdit}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}

            <ProjectForm
                open={isFormOpen}
                onClose={handleCloseForm}
                onSubmit={handleSubmit}
                initialValues={selectedProject || undefined}
                isEdit={!!selectedProject}
            />
        </Container>
    );
};
