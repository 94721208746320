import api from '../../../app/utils/axios';

export const authApi = {
    login: async (credentials) => {
        const response = await api.post('/auth/signin', credentials);
        return response.data;
    },

    register: async (data) => {
        const response = await api.post('/auth/signup', data);
        return response.data;
    },

    logout: async () => {
        const response = await api.post('/auth/signout');
        localStorage.removeItem('token');
        localStorage.removeItem('developer');
        return response.data;
    }
};