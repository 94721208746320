import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from 'react-query';

export function useApi() {
    const { enqueueSnackbar } = useSnackbar();

    const handleError = (error) => {
        const message = error.response?.data?.message || 'Bir hata oluştu';
        enqueueSnackbar(message, { variant: 'error' });
    };

    function useCustomQuery(key, fn, options = {}) {
        return useQuery(key, fn, {
            onError: handleError,
            ...options
        });
    }

    function useCustomMutation(fn, options = {}) {
        return useMutation(fn, {
            onError: handleError,
            ...options
        });
    }

    return {
        query: useCustomQuery,
        mutation: useCustomMutation
    };
}