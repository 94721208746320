import React from 'react';
import { Formik, Form } from 'formik';
import {
    Card,
    CardContent,
    CardHeader,
    TextField,
    Button,
    Box,
    Switch,
    FormControlLabel,
    Alert
} from '@mui/material';
import { useAuth } from '../../../app/hooks/useAuth';
import { useApi } from '../../../app/hooks/useApi';
import { developerApi } from '../api/developerApi';

export const ProfileSettings = () => {
    const { developer, updateDeveloper } = useAuth();
    const { mutation } = useApi();

    const { mutate, isLoading, error } = mutation(
        async (values) => {
            const result = await developerApi.updateDeveloperProfile(values);
            updateDeveloper(result.developer);
            return result;
        },
        {
            successMessage: 'Profil güncellendi'
        }
    );

    return (
        <Card>
            <CardHeader title="Profil Ayarları" />
            <CardContent>
                <Formik
                    initialValues={{
                        developerName: developer.developerName,
                        settings: developer.settings
                    }}
                    onSubmit={mutate}
                >
                    {({ values, handleChange, setFieldValue }) => (
                        <Form>
                            {error && (
                                <Alert severity="error" sx={{ mb: 2 }}>
                                    {error.response?.data?.message || 'Güncelleme başarısız'}
                                </Alert>
                            )}

                            <TextField
                                fullWidth
                                name="developerName"
                                label="Geliştirici/Şirket Adı"
                                value={values.developerName}
                                onChange={handleChange}
                                margin="normal"
                            />

                            <Box sx={{ mt: 3 }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={values.settings.notificationsEnabled}
                                            onChange={(e) =>
                                                setFieldValue('settings.notificationsEnabled', e.target.checked)
                                            }
                                        />
                                    }
                                    label="Email Bildirimleri"
                                />
                            </Box>

                            <Box sx={{ mt: 3 }}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Güncelleniyor...' : 'Güncelle'}
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </CardContent>
        </Card>
    );
};