import React from 'react';
import {
    Container,
    Grid,
    Typography
} from '@mui/material';
import { DeveloperProfile } from '../components/DeveloperProfile';
import { ProfileSettings } from '../components/ProfileSettings';

export const ProfilePage = () => {
    return (
        <Container maxWidth="lg">
            <Typography variant="h4" sx={{ mb: 4 }}>
                Profil
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <DeveloperProfile />
                </Grid>
                <Grid item xs={12} md={8}>
                    <ProfileSettings />
                </Grid>
            </Grid>
        </Container>
    );
};