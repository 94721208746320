import axios from 'axios';
import axiosRetry from 'axios-retry';
import { toast } from 'react-hot-toast';

export const endpoints = {
    auth: {
        signin: '/auth/signin',
        signup: '/auth/signup',
        signout: '/auth/signout'
    },
    developers: {
        me: '/developers/me'
    },
    projects: {
        list: '/projects',
        create: '/projects',
        get: (id) => `/projects/${id}`,
        update: (id) => `/projects/${id}`,
        delete: (id) => `/projects/${id}`
    }
};

const API_CONFIG = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: true
};

// Custom axios instance
const api = axios.create(API_CONFIG);

// Generate unique request ID
const generateRequestId = () => {
    const timestamp = Date.now();
    const random = Math.random().toString(36).substr(2, 9);
    return `req_${timestamp}_${random}`;
};

// Retry configuration
axiosRetry(api, {
    retries: 0,
    retryDelay: (retryCount) => {
        return retryCount * 2000; // time interval between retries
    },
    retryCondition: (error) => {
        // Retry only on network errors or 5xx errors
        return axiosRetry.isNetworkOrIdempotentRequestError(error) ||
            (error.response?.status >= 500 && error.response?.status <= 599);
    }
});

// Request interceptor
api.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('token');

        // Add token if exists
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        if (config.headers) {
            delete config.headers['Origin'];
        }
        // Add request ID
        config.headers['X-Request-Id'] = generateRequestId();

        // Add developer info if exists
        const developer = localStorage.getItem('developer');
        if (developer) {
            const developerData = JSON.parse(developer);
            config.headers['X-Developer-Id'] = developerData.developerId;
        }

        // Add origin header
        //config.headers.Origin = window.location.origin;

        // Log requests in development
        if (process.env.NODE_ENV === 'development') {
            console.log('Request:', {
                method: config.method?.toUpperCase(),
                url: config.url,
                data: config.data,
                headers: config.headers
            });
        }

        return config;
    },
    (error) => {
        console.error('Request error:', error);
        return Promise.reject(error);
    }
);

// Response interceptor
api.interceptors.response.use(
    (response) => {
        // Enhanced logging in development
        if (process.env.NODE_ENV === 'development') {
            const requestId = response.config.headers['X-Request-Id'];
            console.group(`API Response: ${requestId}`);
            console.log('Response:', {
                status: response.status,
                data: response.data,
                headers: response.headers
            });
            console.groupEnd();
        }
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        // Network error - improved message
        if (!error.response) {
            const message = navigator.onLine
                ? 'Network error. Please check your connection.'
                : 'No internet connection. Please check your network settings.';
            toast.error(message);
            return Promise.reject(new Error(message));
        }

        // Mevcut token refresh mekanizmasını koru
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                localStorage.removeItem('token');
                const response = await api.post(endpoints.auth.signin, {
                    developer: localStorage.getItem('developer')
                });

                if (response.data?.token) {
                    localStorage.setItem('token', response.data.token);
                    if (response.data.developer) {
                        localStorage.setItem('developer', JSON.stringify(response.data.developer));
                    }
                    originalRequest.headers.Authorization = `Bearer ${response.data.token}`;
                    return api(originalRequest);
                }
            } catch (refreshError) {
                localStorage.clear();
                window.location.href = '/login';
                return Promise.reject(refreshError);
            }
        }

        // Enhanced error messages
        const errorMessages = {
            400: () => error.response.data?.details?.[0] || error.response.data?.message || 'Invalid request data',
            403: 'Access denied. You do not have permission for this action.',
            404: 'The requested resource was not found.',
            429: 'Too many requests. Please try again later.',
            500: 'An unexpected server error occurred. Please try again.',
            502: 'Service is temporarily unavailable. Please try again later.',
            503: 'Service is currently undergoing maintenance. Please try again later.'
        };

        const statusCode = error.response.status;
        const errorMessage = typeof errorMessages[statusCode] === 'function'
            ? errorMessages[statusCode]()
            : errorMessages[statusCode] || error.response.data?.message || 'An error occurred';

        if (process.env.NODE_ENV !== 'test') {
            toast.error(errorMessage);
        }

        // Enhanced error object
        const enhancedError = new Error(errorMessage);
        enhancedError.requestId = originalRequest?.headers['X-Request-Id'];
        enhancedError.response = error.response;
        enhancedError.status = statusCode;

        return Promise.reject(enhancedError);
    }
);

export default api;