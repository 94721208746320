
import { useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { apiService } from '../services/apiService';

export const useAuth = () => {
    const context = useContext(AuthContext);
    const navigate = useNavigate();

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    const signin = useCallback(async (credentials) => {
        try {
            const response = await apiService.signin(credentials);
            context.login(response.token, response.developer);
            navigate('/projects');
            return response;
        } catch (error) {
            console.error('Signin error:', error);
            throw error;
        }
    }, [context, navigate]);

    const signup = useCallback(async (data) => {
        try {
            const response = await apiService.signup(data);
            context.login(response.token, response.developer);
            navigate('/projects');
            return response;
        } catch (error) {
            console.error('Signup error:', error);
            throw error;
        }
    }, [context, navigate]);

    const signout = useCallback(async () => {
        try {
            await apiService.signout();
        } finally {
            context.logout();
            navigate('/login');
        }
    }, [context, navigate]);

    const updateProfile = useCallback(async (data) => {
        try {
            const response = await apiService.updateDeveloperProfile(data);
            context.updateDeveloper(response.developer);
            return response;
        } catch (error) {
            console.error('Update profile error:', error);
            throw error;
        }
    }, [context]);

    return {
        ...context,
        signin,
        signup,
        signout,
        updateProfile
    };
};