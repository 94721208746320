import * as yup from 'yup';

export const loginSchema = yup.object({
    email: yup.string()
        .email('Geçerli bir email giriniz')
        .required('Email gereklidir'),
    password: yup.string()
        .min(8, 'Şifre en az 8 karakter olmalıdır')
        .required('Şifre gereklidir')
});

export const registerSchema = yup.object({
    email: yup.string()
        .email('Geçerli bir email giriniz')
        .required('Email gereklidir'),
    password: yup.string()
        .min(8, 'Şifre en az 8 karakter olmalıdır')
        .required('Şifre gereklidir'),
    developerName: yup.string()
        .required('Geliştirici adı gereklidir')
});

export const projectSchema = yup.object({
    name: yup.string()
        .required('Proje adı gereklidir')
        .min(3, 'Proje adı en az 3 karakter olmalıdır'),
    description: yup.string(),
    settings: yup.object({
        enableAuth: yup.boolean(),
        enableAnalytics: yup.boolean(),
        enableStore: yup.boolean()
    })
});

const validateEvent = (event, schema) => {
    // HTTP method kontrolü
    if (!event.httpMethod) {
        throw new Error('Invalid event: Missing HTTP method');
    }

    // Path parametreleri kontrolü
    if (schema.pathParameters) {
        if (!event.pathParameters) {
            throw new Error('Missing required path parameters');
        }
        validateFields(event.pathParameters, schema.pathParameters);
    }

    // Query parametreleri kontrolü
    if (schema.queryParameters) {
        if (!event.queryStringParameters) {
            throw new Error('Missing required query parameters');
        }
        validateFields(event.queryStringParameters, schema.queryParameters);
    }

    // Body kontrolü
    if (schema.body) {
        if (!event.body) {
            throw new Error('Request body is required');
        }

        let body;
        try {
            body = JSON.parse(event.body);
        } catch (e) {
            throw new Error('Invalid JSON in request body');
        }

        validateFields(body, schema.body);
        return body;
    }

    return null;
};