import React, { createContext, useState, useContext, useEffect } from 'react';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [developer, setDeveloper] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        try {
            const storedDeveloper = localStorage.getItem('developer');
            if (storedDeveloper) {
                const parsedDeveloper = JSON.parse(storedDeveloper);
                setDeveloper(parsedDeveloper);
            }
        } catch (error) {
            console.error('Failed to parse stored developer data');
            localStorage.removeItem('developer');
            localStorage.removeItem('token');
        } finally {
            setLoading(false);
        }
    }, []);

    const login = (token, developerData) => {
        if (!token || !developerData) return;
        try {
            localStorage.setItem('token', token);
            localStorage.setItem('developer', JSON.stringify(developerData));
            setDeveloper(developerData);
        } catch (error) {
            console.error('Failed to store auth data');
        }
    };

    const logout = () => {
        try {
            localStorage.removeItem('token');
            localStorage.removeItem('developer');
            setDeveloper(null);
        } catch (error) {
            console.error('Failed to clear auth data');
        }
    };

    return (
        <AuthContext.Provider value={{
            developer,
            loading,
            login,
            logout,
            isAuthenticated: !!developer
        }}>
            {children}
        </AuthContext.Provider>
    );
};