import api from '../../../app/utils/axios';


export const developerApi = {
    getDeveloperProfile: async () => {
        const response = await api.get('/developers/me');
        return response.data;
    },

    updateDeveloperProfile: async (data) => {
        const response = await api.put('/developers/me', data);
        return response.data;
    }
};