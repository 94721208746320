import api, { endpoints } from '../utils/axios';

class ApiService {
    // Mevcut metodlar aynen korundu
    async authenticate(credentials) {
        const response = await api.post(endpoints.auth.signin, credentials);
        if (response.data?.token) {
            this.setAuthData(response.data);
        }
        return response;
    }

    async signup(data) {
        const response = await api.post(endpoints.auth.signup, data);
        if (response.data?.token) {
            this.setAuthData(response.data);
        }
        return response;
    }

    async signout() {
        try {
            const response = await api.post(endpoints.auth.signout);
            return response;
        } finally {
            this.clearAuthData();
        }
    }

    async getDeveloperProfile() {
        const response = await api.get(endpoints.developers.me);
        return response;
    }

    async updateDeveloperProfile(data) {
        const response = await api.put(endpoints.developers.me, data);
        return response;
    }

    // Project services - enhanced error handling
    async getProjects() {
        try {
            const response = await api.get(endpoints.projects.list);
            return response;
        } catch (error) {
            console.error('Get Projects Error:', error);
            throw error;
        }
    }

    async getProject(projectId) {
        try {
            const response = await api.get(endpoints.projects.get(projectId));
            return response;
        } catch (error) {
            console.error('Get Project Error:', error);
            throw error;
        }
    }

    async createProject(data) {
        try {
            const response = await api.post(endpoints.projects.create, data);
            return response;
        } catch (error) {
            console.error('Create Project Error:', error);
            throw error;
        }
    }

    async updateProject(projectId, data) {
        try {
            const response = await api.put(endpoints.projects.update(projectId), data);
            return response;
        } catch (error) {
            console.error('Update Project Error:', error);
            throw error;
        }
    }

    async deleteProject(projectId) {
        try {
            const response = await api.delete(endpoints.projects.delete(projectId));
            return response;
        } catch (error) {
            console.error('Delete Project Error:', error);
            throw error;
        }
    }

    // Helper methods - enhanced
    setAuthData(data) {
        try {
            if (data.token) {
                localStorage.setItem('token', data.token);
            }
            if (data.developer) {
                localStorage.setItem('developer', JSON.stringify(data.developer));
            }
        } catch (error) {
            console.error('Error setting auth data:', error);
            this.clearAuthData();
            throw error;
        }
    }

    clearAuthData() {
        localStorage.removeItem('token');
        localStorage.removeItem('developer');
    }

    isAuthenticated() {
        return !!localStorage.getItem('token');
    }

    getDeveloperData() {
        try {
            const data = localStorage.getItem('developer');
            return data ? JSON.parse(data) : null;
        } catch (error) {
            console.error('Error parsing developer data:', error);
            this.clearAuthData();
            return null;
        }
    }

    async testEndpoint(method, endpoint, data = null) {
        try {
            const config = {
                method,
                url: endpoint,
                ...(data && { data })
            };

            console.log('API Request:', {
                method: config.method,
                url: config.url,
                data: config.data
            });

            const response = await api.request(config);

            console.log('API Response:', {
                status: response.status,
                data: response.data
            });

            return response;
        } catch (error) {
            console.error('API Test Error:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status,
                requestId: error.requestId
            });

            if (error.response?.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('developer');
            }

            throw error;
        }
    }
}

export const apiService = new ApiService();