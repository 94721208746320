import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    createRoutesFromElements
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { AuthProvider } from './app/context/AuthContext';
import { MainLayout } from './layouts/MainLayout';
import { LoginPage } from './features/auth/pages/LoginPage';
import { RegisterPage } from './features/auth/pages/RegisterPage';
import { ProjectsPage } from './features/projects/pages/ProjectsPage';
import { ProjectDetailPage } from './features/projects/pages/ProjectDetailPage';
import { ProfilePage } from './features/developers/pages/ProfilePage';
import { SuperSecretTest } from './features/test/pages/SuperSecretTest';
import { PrivateRoute } from './app/components/PrivateRoute';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false
        }
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#6200ea'
        },
        secondary: {
            main: '#0091ea'
        }
    }
});

const routeConfig = {
    future: {
        v7_startTransition: true,
        v7_relativeSplatPath: true
    }
};

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <SnackbarProvider maxSnack={3}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <BrowserRouter {...routeConfig}>
                        <AuthProvider>
                            <Routes>
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/register" element={<RegisterPage />} />
                                <Route path="/" element={
                                    <PrivateRoute>
                                        <MainLayout />
                                    </PrivateRoute>
                                }>
                                    <Route index element={<Navigate to="/projects" replace />} />
                                    <Route path="projects" element={<ProjectsPage />} />
                                    <Route path="projects/:projectId" element={<ProjectDetailPage />} />
                                    <Route path="profile" element={<ProfilePage />} />
                                </Route>
                                <Route path="/supersecrettest" element={<SuperSecretTest />} />
                            </Routes>
                        </AuthProvider>
                    </BrowserRouter>
                </ThemeProvider>
            </SnackbarProvider>
        </QueryClientProvider>
    );
}

export default App;