import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    FormControlLabel,
    Switch,
    Box
} from '@mui/material';
import { Formik, Form } from 'formik';
import { projectSchema } from '../../../app/utils/validation';

export const ProjectForm = ({
                                open,
                                onClose,
                                onSubmit,
                                initialValues = {
                                    name: '',
                                    description: '',
                                    settings: {
                                        enableAuth: true,
                                        enableAnalytics: true,
                                        enableStore: false
                                    }
                                },
                                isEdit = false
                            }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            keepMounted
            disableEscapeKeyDown
            aria-labelledby="project-form-dialog-title"
        >
            <DialogTitle id="project-form-dialog-title">
                {isEdit ? 'Proje Düzenle' : 'Yeni Proje'}
            </DialogTitle>

            <Formik
                initialValues={initialValues}
                validationSchema={projectSchema}
                onSubmit={(values, { setSubmitting }) => {
                    onSubmit(values);
                    setSubmitting(false);
                }}
            >
                {({ values, errors, touched, handleChange, setFieldValue, isSubmitting }) => (
                    <Form>
                        <DialogContent>
                            <TextField
                                fullWidth
                                name="name"
                                label="Proje Adı"
                                value={values.name}
                                onChange={handleChange}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                margin="normal"
                                autoFocus
                            />

                            <TextField
                                fullWidth
                                name="description"
                                label="Açıklama"
                                multiline
                                rows={3}
                                value={values.description}
                                onChange={handleChange}
                                margin="normal"
                            />

                            <Box sx={{ mt: 3 }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={values.settings.enableAuth}
                                            onChange={(e) =>
                                                setFieldValue('settings.enableAuth', e.target.checked)
                                            }
                                            name="settings.enableAuth"
                                        />
                                    }
                                    label="Authentication"
                                />

                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={values.settings.enableAnalytics}
                                            onChange={(e) =>
                                                setFieldValue('settings.enableAnalytics', e.target.checked)
                                            }
                                            name="settings.enableAnalytics"
                                        />
                                    }
                                    label="Analytics"
                                />

                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={values.settings.enableStore}
                                            onChange={(e) =>
                                                setFieldValue('settings.enableStore', e.target.checked)
                                            }
                                            name="settings.enableStore"
                                        />
                                    }
                                    label="In-App Store"
                                />
                            </Box>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                onClick={onClose}
                                disabled={isSubmitting}
                            >
                                İptal
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting}
                            >
                                {isEdit ? 'Güncelle' : 'Oluştur'}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};