import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Chip,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material';
import {
    MoreVert as MoreVertIcon,
    Delete as DeleteIcon,
    Edit as EditIcon
} from '@mui/icons-material';

export const ProjectCard = ({ project, onDelete, onEdit }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = (event) => {
        event.stopPropagation();
        handleClose();
        onDelete(project.projectId);
    };

    const handleEdit = (event) => {
        event.stopPropagation();
        handleClose();
        onEdit(project);
    };

    return (
        <Card
            sx={{
                cursor: 'pointer',
                '&:hover': { boxShadow: 6 }
            }}
            onClick={() => navigate(`/projects/${project.projectId}`)}
        >
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="h6" noWrap>
                        {project.name}
                    </Typography>
                    <IconButton onClick={handleClick} size="small">
                        <MoreVertIcon />
                    </IconButton>
                </Box>

                <Typography color="textSecondary" gutterBottom>
                    ID: {project.projectId}
                </Typography>

                <Box sx={{ mt: 2 }}>
                    <Chip
                        label={project.status}
                        color={project.status === 'ACTIVE' ? 'success' : 'default'}
                        size="small"
                        sx={{ mr: 1 }}
                    />
                    {project.settings.enableAuth && (
                        <Chip label="Auth" size="small" sx={{ mr: 1 }} />
                    )}
                    {project.settings.enableAnalytics && (
                        <Chip label="Analytics" size="small" sx={{ mr: 1 }} />
                    )}
                </Box>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleEdit}>
                        <EditIcon fontSize="small" sx={{ mr: 1 }} />
                        Düzenle
                    </MenuItem>
                    <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
                        <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                        Sil
                    </MenuItem>
                </Menu>
            </CardContent>
        </Card>
    );
};
